<template>
    <div class="container" v-if="id">
        <div class="info-container">
            <div class="info-title">
                <span>{{ detail.name || '-'}}</span>
                <el-button type="primary" icon="el-icon-edit-outline" @click="handleEdit">修改</el-button>
            </div>
            <div class="info-sub">
                <div class="info-sub-item">
                    <span class="key">测评名额: </span>
                    <span class="value">{{ detail.count || '-'}}</span>
                </div>
                <div class="info-sub-item">
                    <span class="key">访问数: </span>
                    <span class="value">{{ detail.used_count || '-'}}</span>
                </div>
                <div class="info-sub-item">
                    <span class="key">创建时间: </span>
                    <span class="value">{{detail.create_time | timeFilter}}</span>
                </div>
            </div>
        </div>
        <div class="border-container">
            <el-row class="search-container" type="flex" align="middle" justify="space-between">
                <el-col :lg="2" :sm="4" :xs="8">
                    <span class="list-title">参与列表</span>
                </el-col>
            </el-row>
            <div class="table-container">
                <el-table :data="tableData" tooltip-effect="dark" style="width: 100%" size="medium"
                    @selection-change="handleSelectionChange"
                    :header-cell-style="{height: '40px', background: 'linear-gradient(180deg,rgba(251,252,253,1) 0%,rgba(246,248,250,1) 100%)'}"
                    :cell-style="{height: '50px',padding: '5px 0'}" header-align="center" :row-key="(row) => row.id">
                    <el-table-column type="selection" width="60" :reserve-selection="true" align="center">
                    </el-table-column>
                    <el-table-column label="姓名" min-width="150" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            <div class="hover-text-colourful fz-bold" style="padding-left: 5px"
                                @click="routerChange(1,scope.row)">
                                {{scope.row.user_name || '-'}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="拓展渠道" min-width="100" :show-overflow-tooltip="true" align="center">
                        <span slot-scope="scope">
                            <template>{{scope.row.promotion_channel_name || '-'}}</template>
                        </span>
                    </el-table-column>
                    <el-table-column label="联系方式" min-width="100" :show-overflow-tooltip="true" align="center">
                        <span slot-scope="scope">
                            <template>{{scope.row.user_phone || '-'}}</template>
                        </span>
                    </el-table-column>

                    <el-table-column label="备注" min-width="120" align="center">
                        <template slot-scope="scope">
                            <!-- <i class="iconfont icon-point" :class="scope.row.status | courseStatusFilter('class')" /> -->
                            {{scope.row.remark || '-'}}
                        </template>
                    </el-table-column>
                    <el-table-column label="参与时间" min-width="155" align="center">
                        <template slot-scope="scope">
                            {{scope.row.create_time | timeFilter}}
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" min-width="150" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" class="icon-btn" @click="routerChange(1,scope.row)">
                                <el-tooltip placement="top" content="详情">
                                    <i class="iconfont icon-check" />
                                </el-tooltip>
                            </el-button>
                            <el-button type="text" class="icon-btn" @click="handleDeleteRecord(scope.row.id)">
                                <el-tooltip placement="top" content="删除">
                                    <i class="iconfont icon-delete" />
                                </el-tooltip>
                            </el-button>
                        </template>
                    </el-table-column>
                    <div class="" slot="empty">
                        <no-data></no-data>
                    </div>
                </el-table>

            </div>
            <div class="page-container">
                <el-pagination layout="total, sizes, prev, pager, next" background @size-change="pageSizeChange"
                    @current-change="pageChange" :current-page="currentPage" :page-sizes="[total, 10, 15, 20, 25]"
                    :total="total" :page-size="pageSize">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import Core from '@/core';
export default {
    components: {
        NoData: () => import('@/components/Empty.vue')
    },
    props: {},
    data() {
        return {
            id: '',
            detail: {},
            tableData: [],
            currentPage: 1,
            pageSize: 10,
            total: 0,

            multipleSelection: []
        };
    },
    watch: {},
    computed: {},
    created() {
        this.id = this.$route.query.id;
    },
    mounted() {
        this.getDetail();
        this.getTableData();
    },
    methods: {
        getDetail() {
            Core.operationApi.Evaluation.detail(this.id).then((res) => {
                this.detail = res.detail;
            });
        },
        getTableData() {
            Core.operationApi.Evaluation.paging(
                this.id,
                '',
                '',
                this.currentPage,
                this.pageSize
            ).then((res) => {
                console.log('getTableData res:', res);
                this.total = res.list.count;
                this.tableData = res.list.list;
            });
        },
        pageChange(page) {
            this.currentPage = page;
            this.getTableData();
        },
        pageSizeChange(size) {
            this.pageSize = size;
            this.getTableData();
        },
        handleSelectionChange(val) {
            this.multipleSelection = val.map((item) => item.id);
            console.log(this.multipleSelection);
        },
        handleEdit() {
            this.$router.push({
                path: '/operate-quotient/event-edit',
                query: {
                    id: this.id
                }
            });
        },
        routerChange(key, item) {
            console.log('item', item);
            switch (key) {
                case 1: // 查看详情
                    this.$router.push({
                        path: '/operate-quotient/record-detail',
                        query: {
                            id: item.customer_id,
                            record_id: item.record_id,
                            evaluation_id: item.evaluation_data_id
                        }
                    });
                    break;
            }
        },
        handleDeleteRecord(id) {
            // 删除记录
            const h = this.$createElement;
            this.$msgbox({
                title: '确定要删除吗?',
                message: h('p', null, [
                    h('span', null, '删除'),
                    h('strong', null, '测评报告'),
                    h('span', null, '会将对应的'),
                    h('strong', null, '邀请记录'),
                    h('span', null, '也删除')
                ]),
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                Core.operationApi.Invite.delete(id)
                    .then((res) => {
                        this.$message.success('删除成功!');
                        this.getTableData();
                    })
                    .catch((err) => {
                        console.log('deleteInvite -> err', err);
                        this.$message.warning('删除失败!');
                    });
            });
        }
    }
};
</script>
<style lang="scss" scoped>
.container {
    @import '@/common/styles/table.scss';
    .info-container {
        width: 100%;
        background: #ffffff;
        box-sizing: border-box;
        padding: 0 30px;
        .info-title {
            display: flex;
            justify-content: space-between;
            padding-top: 20px;
            font-size: 20px;
            font-weight: 500;
            color: $color-black;
            line-height: 28px;
        }
        .info-sub {
            @include flex(row, space-between, center);
            flex-wrap: wrap;
            padding-top: 30px;
            padding-bottom: 20px;
            &-item {
                width: 250px;
                span {
                    font-size: $font-size-base;
                    font-weight: 400;
                    line-height: 20px;
                }
                .key {
                    color: $color-black-light;
                }
                .value {
                    color: $color-black;
                }
            }
        }
    }
    .border-container {
        margin-top: 20px;
        width: 98%;
        margin-left: 1%;
    }
}
</style>